import { Product } from "api/sdk";
import SEO from "components/SEO";
import { Link } from "gatsby";
import Layout from "layout";
import React from "react";
import { formatPrice } from "utils/helpers";
import {
  BasePageContext,
  TemplateHeadProps,
  TemplatePageProps,
} from "utils/types";
const htHomeImg = require("images/hillary-taylor-home.jpg").default;

export interface ProductIndexPageContext extends BasePageContext {
  /**
   * The title to display on the page.
   * @default "Products"
   */
  title?: string;
  /**
   * The products to display on the page.
   * @default all products in appData
   */
  products?: Product[];
}

export default (props: TemplatePageProps<ProductIndexPageContext>) => {
  const {
    appData,
    title = "Products",
    products = appData.products
  } = props.pageContext;
  var sortedProducts = products.slice().sort( (prev, value) => { 
    var soldOut = 0
    if (value.inventory === 0) {
      soldOut += appData.products.length
    }
    return ( prev.order - value.order) + soldOut
  } )
  console.log(sortedProducts)


  return (
    <Layout appData={appData} bodyClass="shop">
      <div className="spaced-50">
        <h1 className="with-divider">{title}</h1>
        <div className="container shop-container" style={{ maxWidth: 1100 }}>
          {sortedProducts.filter((it) => it.inventory !== 0).map((product) => {
            if (product.isPublished) {
              return (
                <div key={product._id} className="shop-card ecom-shop-card">
                  <Link to={`/products/${product.slug}`} className="image-link">
                    <img
                      src={
                        !!product.images?.length ? product.images[0] : undefined
                      }
                      style={{ objectFit: "contain" }}
                      alt={`${product.name} image`}
                    />
                    <button className="button blue">VIEW</button>
                  </Link>
                  <Link to={`/products/${product.slug}`}>
                    <h3>{product.name}</h3>
                  </Link>
                  <span className="price-range">
                    {product.inventory ? formatPrice(product.price) : "Sold Out"}
                  </span>
                </div>
              );
            }
          })}
          {sortedProducts.filter((it) => it.inventory === 0).map((product) => {
          if (product.isPublished) {
            return (
              <div key={product._id} className="shop-card ecom-shop-card">
                <Link to={`/products/${product.slug}`} className="image-link">
                  <img
                    src={
                      !!product.images?.length ? product.images[0] : undefined
                    }
                    style={{ objectFit: "contain" }}
                    alt={`${product.name} image`}
                  />
                  <button className="button blue">VIEW</button>
                </Link>
                <Link to={`/products/${product.slug}`}>
                  <h3>{product.name}</h3>
                </Link>
                <span className="price-range">
                  {product.inventory ? formatPrice(product.price) : "Sold Out"}
                </span>
              </div>
            );
          }
          })}
        </div>
      </div>
    </Layout>
  );
};

export const Head = (props: TemplateHeadProps<ProductIndexPageContext>) => {
  const {
    appData,
    title = "Products",
    products = appData.products,
  } = props.pageContext;

  return (
    <SEO
      title={title}
    // ogMeta={{
    //   image: products[0]?.images[0] ?? htHomeImg,
    // }}
    ></SEO>
  );
};
